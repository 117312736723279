import { Activity } from '../utils/constants'

import { darkTheme } from './dark-theme'
import { lightTheme } from './light-theme'

export interface PropsTheme {
  theme: LightTheme | DarkTheme
}

export interface LightTheme {
  colors: {
    main: string
    secondary: string
    hover: string
    dark: string
    whiteGrey: string
    whiteMixGrey: string
    whiteSnow: string
    medium: string
    darkGrey: string
    mediumGrey: string
    lightGrey: string
    veryLightGrey: string
    danger: string
    lightWarning: string
    warning: string
    success: string
    info: string
    whiteOff: string
    white: string
    cheerful: string
    cyan: string
    safetyOrange: string
  }
  productColorStatus: {
    active: string
    inactive: string
    draft: string
  }
  urgency: {
    high_urgent: string
    med_urgent: string
    low_urgent: string
  }
  orderColorStatus: {
    raw: string
    draft: string
    wait_for_production: string
    wait_for_approval: string
    made_to_order: string
    assembly: string
    qc_passed: string
    open: string
    ready_to_ship: string
    prepick: string
    prepared: string
    picked: string
    printed: string
    packed: string
    boarded: string
    transported: string
    delivered: string
    cancelled: string
    wait_return: string
    returned: string
    doubted: string
    lost: string
    unknown_source_status: string
    resolved_by_marketplace: string
    fleet_in_progress: string
    fleeted: string
    fleet_ready: string
    rejected_approval: string
  }
  paymentColorStatus: {
    unpaid: string
    cod: string
    partial: string
    partial_cod: string
    paid: string
    transferred: string
    cancelled: string
    wait_refund: string
    refunded: string
    free: string
  }
  paymentVerifyColorStatus: {
    verified: string
    not_verified: string
    partial_verified: string
    issue: string
  }
  paymentVerifyStatusOptionBgColor: {
    verified: string
    not_verified: string
    issue: string
  }
  paymentVerifyStatusOptionTextColor: {
    verified: string
    not_verified: string
    issue: string
  }
  slipColorStatus: {
    verified: string
    not_verified: string
    issue: string
  }
  bankTxColorStatus: {
    matched: string
    not_matched: string
    partial_matched: string
    issue: string
  }
  saleColorStatus: {
    bought: string
    bought_other_channel: string
    draft: string
    wait_to_decide: string
    ask_question: string
    line_menu: string
    blocked: string
  }
  purchaseRequisitionColorStatus: {
    open: string
    request_super_approval: string
    approved: string
    rejected: string
    returned: string
    request_purchase_confirm: string
    wait_for_po_confirm: string
    request_po_confirm: string
  }
  purchaseRequisitionPaymentColorStatus: {
    partial: string
    paid: string
  }
  purchaseColorStatus: {
    open: string
    request_approval: string
    request_purchase_confirm: string
    request_super_approval: string
    approved: string
    paid: string
    purchased: string
    received_correct: string
    received_incorrect: string
    rejected: string
    returned: string

    wait_purchase_add_receive_date: string
    wait_for_documents: string
    in_transit_to_warehouse: string
    warehouse_received: string
  }
  purchasePaymentColorStatus: {
    unpaid: string
    partial: string
    credit: string
    paid: string
    cancelled: string
    wait_refund: string
    refunded: string
  }
  invAdjustmentOrderStatus: {
    wait_approval: string
    approved: string
    confirmed: string
    draft: string
    open: string
    in_progress: string
    done: string
    cancelled: string
  }
  progressBarColor: {
    open: string
    inProgress: string
    done: string
    cancelled: string
    inDoubt: string
  }
  ProductTrackingActionStatus: {
    TODO: string
    IN_PROGRESS: string
    DONE: string
  }
  productTrackingStatus: {
    active: string
    in_active: string
    cancelled: string
  }
  exportColor: {
    detail: string
    shipper: string
    receiver: string
    other: string
  }
  buttonColor: {
    primary: string
    primaryHover: string
    primaryDisabled: string

    primaryFontColor: string
    primaryHoverFontColor: string
    primaryDisabledFontColor: string

    secondary: string
    secondaryHover: string
    secondaryDisabled: string

    secondaryFontColor: string
    secondaryHoverFontColor: string
    secondaryDisabledFontColor: string
  }
  iconColor: {
    light: string
  }
  fontColor: {
    dark: string
    gray: string
    lightGray: string
    mediumPlus: string
    medium: string
    light: string
    main: string
    lightMain: string
    secondary: string
    info: string
    danger: string
    warning: string
    success: string
  }
  shadowColor: {
    light: string
    veryLight: string
    lightest: string
  }
  fontFamily: string
  fontSizes: {
    sm: string
    smMinus: string
    md: string
    lg: string
    lgPlus: string
    xl: string
  }
  fontSizesPx: {
    sm: string
    smMinus: string
    md: string
    lg: string
    lgPlus: string
    xl: string
  }
  notificationsColorStatus: {
    open: string
    in_progress: string
    resolved: string
  }
  activityColorStatus: {
    [Activity.ASSIGN_PRODUCTION_JOB]: string
    [Activity.CLOSE_PRODUCTION_JOB]: string
    [Activity.DEFECT_PRODUCT]: string
    [Activity.IMPORT_TO_INVENTORY_JOB]: string
  }
  deliveryFleetColorStatus: {
    draft: string
    queued: string
    shipping: string
    finished: string
  }
  accountingOrderColumnHeaderColors: {
    platform: string
    fm: string
    calculation: string
  }
  ticketAction: {
    submit_ticket: string
    appeal: string
    approved: string
    wait_for_approve: string
    wait_for_inform: string
    wait_for_customer_decide: string
    negotiate: string
    picked_to_send_new: string
    picked_to_claim: string
    picked_to_repair: string
    picked_to_service: string
    create_incomplete_deliver_order: string
    create_claim_order: string
    create_retrieve_order: string
    received_back: string
    boarded_new: string
    delivered: string
    accept: string
    done: string
    disapproved: string
    killswitch: string
  }
  ticketResponsibility: {
    submit_ticket: string
    appeal: string
    approved: string
    wait_for_approve: string
    wait_for_inform: string
    wait_for_customer_decide: string
    negotiate: string
    picked_to_claim: string
    picked_to_repair: string
    picked_to_service: string
    create_incomplete_deliver_order: string
  }
  trackingStatusBar: {
    complete: string
    all_wip_prepared: string
    wip_preparing: string
    wait_prepare: string
  }
}

export interface DarkTheme {
  colors: {
    main: string
    secondary: string
    hover: string
    dark: string
    whiteGrey: string
    whiteMixGrey: string
    whiteSnow: string
    medium: string
    darkGrey: string
    mediumGrey: string
    lightGrey: string
    veryLightGrey: string
    danger: string
    lightWarning: string
    warning: string
    success: string
    info: string
    whiteOff: string
    white: string
    cheerful: string
    cyan: string
    safetyOrange: string
  }
  productColorStatus: {
    active: string
    inactive: string
    draft: string
  }
  orderColorStatus: {
    draft: string
    wait_for_production: string
    open: string
    ready_to_ship: string
    assembly: string
    qc_passed: string
    prepick: string
    prepared: string
    picked: string
    printed: string
    packed: string
    boarded: string
    transported: string
    delivered: string
    cancelled: string
    wait_return: string
    returned: string
    doubted: string
    lost: string
    unknown_source_status: string
    resolved_by_marketplace: string
  }
  urgency: {
    high_urgent: string
    med_urgent: string
    low_urgent: string
  }
  paymentColorStatus: {
    unpaid: string
    cod: string
    partial: string
    partial_cod: string
    paid: string
    transferred: string
    cancelled: string
    wait_refund: string
    refunded: string
    free: string
  }
  paymentVerifyColorStatus: {
    verified: string
    not_verified: string
    partial_verified: string
    issue: string
  }
  paymentVerifyStatusOptionBgColor: {
    verified: string
    not_verified: string
    issue: string
  }
  paymentVerifyStatusOptionTextColor: {
    verified: string
    not_verified: string
    issue: string
  }
  slipColorStatus: {
    verified: string
    not_verified: string
    issue: string
  }
  bankTxColorStatus: {
    matched: string
    not_matched: string
    issue: string
  }
  saleColorStatus: {
    bought: string
    bought_other_channel: string
    draft: string
    wait_to_decide: string
    ask_question: string
    line_menu: string
    blocked: string
  }
  purchaseRequisitionColorStatus: {
    open: string
    request_super_approval: string
    approved: string
    rejected: string
    request_purchase_confirm: string
    wait_for_po_confirm: string
    request_po_confirm: string
  }
  purchaseRequisitionPaymentColorStatus: {
    partial: string
    paid: string
  }
  purchaseColorStatus: {
    // open: string
    // processed_vendor: string
    // in_transit_remote: string
    // in_transit_local: string
    // received: string
    // cancelled: string
    // doubted: string
    // wait_return: string
    // returned: string

    open: string
    request_approval: string
    request_purchase_confirm: string
    wait_purchase_add_receive_date: string
    in_transit_to_warehouse: string
    warehouse_received: string
    wait_for_documents: string
    finished: string
    rejected: string
    returned: string
  }
  purchasePaymentColorStatus: {
    unpaid: string
    partial: string
    credit: string
    paid: string
    cancelled: string
    wait_refund: string
    refunded: string
  }
  invAdjustmentOrderStatus: {
    wait_approval: string
    approved: string
    confirmed: string
    draft: string
    open: string
    in_progress: string
    done: string
    cancelled: string
  }
  progressBarColor: {
    open: string
    inProgress: string
    done: string
    cancelled: string
    inDoubt: string
  }
  exportColor: {
    detail: string
    shipper: string
    receiver: string
    other: string
  }
  ProductTrackingActionStatus: {
    todo: string
    in_progress: string
    done: string
  }
  productTrackingStatus: {
    active: string
    in_active: string
    cancelled: string
  }
  buttonColor: {
    primary: string
    primaryHover: string
    primaryDisabled: string

    primaryFontColor: string
    primaryHoverFontColor: string
    primaryDisabledFontColor: string

    secondary: string
    secondaryHover: string
    secondaryDisabled: string

    secondaryFontColor: string
    secondaryHoverFontColor: string
    secondaryDisabledFontColor: string
  }
  iconColor: {
    light: string
  }
  fontColor: {
    dark: string
    mediumPlus: string
    medium: string
    gray: string
    lightGray: string
    light: string
    primary: string
    secondary: string
    info: string
    danger: string
    warning: string
    success: string
    main: string
    lightMain: string
  }
  shadowColor: {
    light: string
    veryLight: string
    lightest: string
  }
  fontFamily: string
  fontSizes: {
    sm: string
    smMinus: string
    md: string
    lg: string
    lgPlus: string
    xl: string
  }
  fontSizesPx: {
    sm: string
    smMinus: string
    md: string
    lg: string
    lgPlus: string
    xl: string
  }
  notificationsColorStatus: {
    open: string
    in_progress: string
    resolved: string
  }
  activityColorStatus: {
    [Activity.ASSIGN_PRODUCTION_JOB]: string
    [Activity.CLOSE_PRODUCTION_JOB]: string
    [Activity.DEFECT_PRODUCT]: string
    [Activity.IMPORT_TO_INVENTORY_JOB]: string
  }
  deliveryFleetColorStatus: {
    draft: string
    queued: string
    shipping: string
    finished: string
  }
  accountingOrderColumnHeaderColors: {
    platform: string
    fm: string
    calculation: string
  }
  ticketAction: {
    wait_for_approve: string
    wait_for_inform: string
    wait_for_customer_decide: string
    negotiate: string

    appeal: string
    approved: string
    disapproved: string
    // accept
    killswitch: string
    done: string
  }
  ticketResponsibility: {
    submit_ticket: string
    appeal: string
    approved: string
    wait_for_approve: string
    wait_for_inform: string
    wait_for_customer_decide: string
    negotiate: string
    picked_to_claim: string
    picked_to_repair: string
    picked_to_service: string
    create_incomplete_deliver_order: string
  }
  trackingStatusBar: {
    complete: string
    all_wip_prepared: string
    wip_preparing: string
    wait_prepare: string
  }
}

export { darkTheme, lightTheme }
